import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import APLBoxes from "./APLBoxes";

const APLInfo = (props) => {
  const theme = useTheme();
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [boxHeight, setBoxHeight] = React.useState("1000px");
  const [imageLoaded, setImagesLoaded] = React.useState(0);

  useEffect(() => {
    if (props.anchorEl) {
      const anchorRect = props.anchorEl.getBoundingClientRect();
      setPosition({
        left: anchorRect.left + anchorRect.width / 2 - 20,
        top: anchorRect.bottom,
      });
    }
    const box = document.getElementById("apl-box");
    setBoxHeight(box.getBoundingClientRect().height + "px");
    console.log(box.getBoundingClientRect().height);
  }, [props.anchorEl, imageLoaded]);

  return (
    <div style={{ marginTop: "30px", height: boxHeight, display: "flex" }}>
      <Box
        id="apl-box"
        sx={{
          position: "absolute",
          height: "fit-content",
          backgroundColor: theme.palette.secondary.experience,
          width: "100% !important",
          left: "0px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-25px",
            left: position.left,
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "45px solid transparent",
            borderRight: "45px solid transparent",
            borderBottom: "45px solid" + theme.palette.secondary.experience,
          }}
        ></Box>
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Box>
            <h4 style={{ marginBottom: "20px" }}>
              Austin Public Library (APL)
            </h4>
            {/* <h3>
              Currently in the process of redesigning the APL Events page for
              the website, using my skills as a designer to enhance the user
              experience and visual appeal.
            </h3> */}
          </Box>

          <Box sx={{ margin: "20px" }}>
            <Box>
              <h3 style={{ textAlign: "left" }}>OVERVIEW</h3>
              <h2 style={{ textAlign: "left", fontWeight: "300" }}>
                As a design fellow in Texas Product Engineering Organization, I
                was partnered with the Austin Public Library to redesign their
                events page. As the sole designer, I led our user research and
                design iterations.
              </h2>
            </Box>
            <Divider horizontal sx={{ margin: "20px" }}></Divider>
            <Grid container>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TIMELINE
                </h3>
                <h3 style={{ textAlign: "left" }}>
                  September 2023 - April 2024
                </h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>SKILLS</h3>
                <h3 style={{ textAlign: "left" }}>
                  Figma <br />
                </h3>
                <h3 style={{ textAlign: "left" }}>Adobe Photoshop</h3>
                <h3 style={{ textAlign: "left" }}>Adobe Illustrator</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>TEAM</h3>
                <h3 style={{ textAlign: "left" }}>
                  Developers: Conny Cai, Nayeon Kim
                </h3>
                <h3 style={{ textAlign: "left" }}>Designer: Raina Parikh</h3>
                <h3 style={{ textAlign: "left" }}>
                  Project Manager: Sarvesh Subramanian
                </h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TAKAWAYS
                </h3>
                <h3 style={{ textAlign: "left" }}>
                  Working as a designer on a team
                </h3>
                <h3 style={{ textAlign: "left" }}>Utilizing Figma</h3>
              </Grid>
            </Grid>
          </Box>
          <APLBoxes setImagesLoaded={setImagesLoaded}></APLBoxes>
        </Box>
      </Box>
    </div>
  );
};

export default APLInfo;
