import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import figjam from "../images/jamboard.svg";
import competitive_analysis from "../images/aplcompetitiveanalysis.png";
import low_fi from "../images/apl low fi.png";
import mid_fi from "../images/apl mid fi.png";
import high_fi from "../images/apl high fi.png";
import interview from "../images/interview.png";
import { useTheme } from "@mui/material/styles";

const APLBoxes = (props) => {
  const theme = useTheme();

  const handleImageLoad = () => {
    props.setImagesLoaded((prev) => prev + 1);
  };

  return (
    <div style={{ height: "fit-content" }}>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  src={figjam}
                  alt="FigJam"
                  style={{
                    width: "90%",
                    borderRadius: "40px",
                    padding: "20px",
                  }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>STEP 1: BRAINSTORMING</h3>
                <h3>
                  We began using Figma JamBoard to map out user flow, carefully
                  marking what areas need to be improved. We moved to analyzing
                  other library websites to test their user flow against ours
                  and marked out our problem statement
                </h3>
                <Box
                  sx={{
                    backgroundColor: theme.palette.secondary.experience,
                    width: "100%",
                    height: "115px",
                    borderRadius: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%" // You can adjust this to your preferred height
                    sx={{ padding: "5px" }}
                  >
                    <h3 style={{ margin: "5px" }}>PROBLEM</h3>
                    <h3
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        fontWeight: "700",
                      }}
                    >
                      Finding, editing, and entering events is difficult for the
                      librarians and library goers
                    </h3>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderRadius: "30px" }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              height: "fit-content",
              width: "100%",
              borderRadius: "30px",
              marginBottom: "30px",
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
                <Box sx={{ margin: "30px" }}>
                  <h3 style={{ fontWeight: "700" }}>STEP 2: USER INTERVIEWS</h3>
                  <h3>
                    We interviewed library goers of all types, from families to
                    teenagers, to grasp how people find library events. They
                    preferred to have the information easily accessible on the
                    website (ability to shift through multiple events) and said
                    the library website would be there first stop for event
                    information.
                  </h3>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.secondary.experience,
                      width: "100%",
                      height: "115px",
                      borderRadius: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                      justifyContent="left"
                      height="100%" // You can adjust this to your preferred height
                      sx={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "30px",
                      }}
                    >
                      <h3 style={{ margin: "5px" }}>PRIORITIES</h3>
                      <h3
                        style={{
                          textAlign: "left",
                          margin: "5px",
                          fontWeight: "700",
                        }}
                      >
                        1. Age <br />
                        2. Time <br />
                        3. Location
                      </h3>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <img
                    src={interview}
                    alt="Interview Script"
                    style={{
                      width: "90%",
                      borderRadius: "40px",
                      padding: "20px",
                    }}
                    onLoad={handleImageLoad}
                  ></img>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  src={competitive_analysis}
                  alt="Competitive Analysis in FigJam"
                  style={{
                    width: "90%",
                    borderRadius: "40px",
                    padding: "20px",
                  }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>
                  STEP 3: COMPETITIVE ANALYSIS
                </h3>
                <h3>
                  Using FigJam, I analyzed competitors websites including the
                  Fort Bend County Library - my hometown library - Dallas County
                  Library, and San Antonio Library. Based on my findings, I was
                  able to point out the shortcomings of the APL and brainstorm
                  features to add.
                </h3>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>
                  STEP 4: LOW FIDELITY DESIGNS
                </h3>
                <h3>
                  Next, using Figma, I began to mock up an outline of what the
                  new events page could look like. During this process, we met
                  with the stakeholders to ensure they were satisfied with the
                  features being added and to get their input.
                </h3>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  src={low_fi}
                  alt="Low Fidelity Designs"
                  style={{
                    width: "90%",
                    borderRadius: "40px",
                    padding: "20px",
                  }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  src={mid_fi}
                  alt="Mid Fidelity Designs"
                  style={{
                    width: "90%",
                    borderRadius: "40px",
                    padding: "20px",
                  }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>STEP 5: MID FIDELITY</h3>
                <h3>
                  At the mid fidelity stage, I began brainstorming color. At
                  this point, APL did not have a solid brand guidelines, so I
                  worked on putting together a more cohesive design for them.
                  This required looking into ADA compliance to verify the
                  website was accessible to everyone.
                </h3>
                <br />
                <h3>
                  During this stage, I also receieved critiques on my designs
                  from fellow design members and our stake holders. We had an in
                  person stakeholder meeting to dsicuss the next steps. I also
                  frequently met with our engineers to discuss feasability for
                  the overall project.
                </h3>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>STEP 6: HIGH FIDELITY</h3>
                <h3>
                  Finally, after much revision, the high fidelity designs were
                  completed and ready for engineering to build out. To view the
                  final designs, visit{" "}
                  <a href="https://www.figma.com/design/HSK7uRzyjpb35M9numxumb/Austin-Public-Library?node-id=312-928">
                    this link!
                  </a>
                </h3>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  src={high_fi}
                  alt="High Fidelity Designs"
                  style={{
                    width: "90%",
                    borderRadius: "40px",
                    padding: "20px",
                  }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default APLBoxes;
