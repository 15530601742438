import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import laptop from "../images/laptop.svg";
import phone from "../images/phones.svg";
import { useTheme } from "@mui/material/styles";

const TransferBoxes = (props) => {
  const theme = useTheme();

  const handleImageLoad = () => {
    props.setImagesLoaded((prev) => prev + 1);
  };

  return (
    <div style={{ height: "fit-content" }}>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                sx={{ padding: "10px" }}
              >
                <img
                  src={laptop}
                  alt="Desktop View"
                  style={{ width: "90%", borderRadius: "10px" }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>SAVED PLAYERS</h3>
                <h3>
                  Coaches can save players by clicking the star on the left side
                  of the table. They can filter the table to only show saved
                  players and access the player page from the saved menu
                </h3>
                <h3 style={{ fontWeight: "700" }}>HISTORY FEATURE</h3>
                <h3>
                  Coaches can view the player table with different year
                  stastics.
                </h3>
                <h3 style={{ fontWeight: "700" }}>PLAYER PAGE</h3>
                <h3>
                  When viewing a player, coaches can see a shot chart, in-depth
                  predictions, and share notes with each other. Due to
                  information security, I cannot share an image of this.
                </h3>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderRadius: "30px" }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              height: "fit-content",
              width: "100%",
              borderRadius: "30px",
              marginBottom: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
                <Box
                  sx={{
                    margin: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "center",
                  }}
                >
                  <h3 style={{ fontWeight: "700", textAlign: "left" }}>
                    MOBILIZATION
                  </h3>
                  <h3>
                    The transfer portal is designed to ensure optimal user
                    experience across various devices, including seamless
                    viewing on mobile devices.
                  </h3>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  sx={{ padding: "10px" }}
                >
                  <img
                    src={phone}
                    alt="Mobile View"
                    style={{ width: "90%", borderRadius: "10px" }}
                    onLoad={handleImageLoad}
                  ></img>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default TransferBoxes;
